import React, { useEffect } from "react";
import Layout from "../../components-pl/layout";
import CheckoutForm from "../../components-pl/checkout/checkout-form";
import SEO from "../../components/seo";

const Checkout = () => {
  useEffect(() => {
    document.getElementById("lang-switch").href = "/checkout/";
  }, []);
  return (
    <>
      <SEO
        title={"Kup napoje shroom!"}
        description={""}
        header={{ siteTitle: " Shroom | Zamówienie" }}
      />
      <Layout>
        <div className="checkout container my-5">
          <h1 className="mt-5 mb-4 spec-h" style={{ textAlign: "center" }}>
            Zamówienie
          </h1>
          <CheckoutForm />
        </div>
      </Layout>
    </>
  );
};

export default Checkout;
